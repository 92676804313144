section {
    &.hero__banner {
        background-color: #fff;
        color: white;
        overflow: hidden;
        height: 510px;

        @include media-breakpoint-up(xl) {
            height: 510px;
        }

        .hero__banner__inner {
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            overflow: hidden;
            z-index:3;

            .container {
                z-index: 1;
            }

            .hero__banner-text{
                margin-top: 50px;
            }

            .video-container {
                position: absolute;
                height: 100%;
                width: 100vw;
                left: 0;
                top: 0;
                overflow: hidden;

                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.4);
                    z-index: 1;
                }


                @media screen and (orientation: landscape) {

                    @include media-breakpoint-only(sm) {
                        height: 100vh;
                    }
                }

                iframe {
                    width: 100vw;
                    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
                    min-height: 100vh;
                    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            p{
                font-size:1.8rem;
                line-height:1.88;
                max-width: 600px;
            }
        }
    }
}


body.animate {
    section {
        &.hero__banner {
            .hero__banner__inner {
                opacity: 0;
                .hero__banner-text {
                    opacity: 0;
                }
            }
        }
    }
}


body:not(.animate) {
    section {
        &.hero__banner {
            .hero__banner__inner {
                opacity: 1;
                transition: opacity 1s ease-in-out;
                .hero__banner-text {
                    opacity: 1;
                    transform: translateY(0);
                    transition: all 0.4s ease-in-out 1s;
                }
            }
        }
    }
}
