body[data-view="home"] {
    .top__cta  {

        .container {
            @include media-breakpoint-up(md) {
                background-color: white;
                border-radius: 50px;
            }
        }
    }

    section {

        &.hero__banner {
            height: calc(100vh - 90px);
            min-height: 500px;

            @include media-breakpoint-down(md) {
                height: calc(100vh - 75px);
            }

            @include media-breakpoint-down(xs) {
                height: calc(100vh - 180px);
            }

            @include media-breakpoint-up(xl) {
                min-height: 700px;
            }

            @media screen and (orientation: landscape) {

                @include media-breakpoint-only(lg) {
                height: calc(100vh - 75px);
                }

                @include media-breakpoint-only(sm) {
                    min-height: 100vh;
                }
            }
        }

        &.cards__text {
            margin-bottom: 100px;
            position: relative;

        }

        &#cards_text_home_1 {
            .content {
                transform: translateY(60px);

                @include media-breakpoint-up(xl) {
                    transform: translateY(200px);

                }
            }
        }

        &#cards_text_home_2 {
            background-color: black;
            margin-bottom: 0;
            padding: 60px 0 140px;
            color: white;

            .cards__text__inner {

                &::before {
                    content: url("../../../images/promark-t-white.svg");
                    position: absolute;
                    left: -75px;
                    top: -150px;
                    transform: scale(0.5);
    
                    @include media-breakpoint-up(md) {
                        transform: scale(0.7);
                        top: -70px;
                    }
    
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                    @include media-breakpoint-up(xl) {
                        top: -15px;
                    }
    
                    @include media-breakpoint-up(laptop) {
                        transform: scale(1);
                        left: -25px;
                        top: 10%;
                    }
                    @include media-breakpoint-up(hd) {
                        left: -0px;
                    }
                }

            }
            
            .content {
                padding-left: 30px;
                transform: translateY(100px);
                margin-bottom: 145px;

                @include media-breakpoint-up(md) {
                    transform: translateY(175px);
                }
                @include media-breakpoint-up(lg) {
                    transform: translateY(175px);
                    margin-bottom: 0;
                }
                @include media-breakpoint-up(xl) {
                    transform: translateY(215px);
                }
                @include media-breakpoint-up(laptop) {
                    transform: translateY(350px);
                }
                @include media-breakpoint-up(hd) {
                    padding-left: 0;
                }

            }
        }

        &#text_background_home_1 {

            .text__background__inner {
                padding: 150px 0 250px;

                @include media-breakpoint-up(md) {
                    padding: 150px 0 200px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 250px 0 300px;
                }

                .content {
                    max-width: 480px;
                    padding: 25px;



                    @include media-breakpoint-up(md) {
                        width: 100%;
                        height: 560px;
                        padding: 0 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .btn {
                        margin-top: 40px;

                        @include media-breakpoint-up(md) {
                            margin-top: 65px;
                        }
                    }

                }
            }
        }
    }
}

/* ANIMATIONS */

body.animate {
    #top_cta_home {
        .container {
            transform: translateY(42.5px);
            max-width: 350px;
            opacity: 0;
            height: 85px;
            overflow: hidden;

            .top__cta--contact {
                opacity: 0;
            }
        }
    }

    section {
        &#cards_text_home_1 {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}


body:not(.animate) {

    #top_cta_home {
        .container {
            opacity: 1;
            width: auto;
            height: auto;
            overflow: visible;
            transform: translateY(0);
            transition: opacity 0.4s ease-in-out 0.2s,
            transform 0.4s ease-in-out 0.4s,
            max-width 0.4s ease-in-out 0.6s,
            height 0.4s ease-in-out 0.8s;

        }

        .top__cta--contact {
            opacity: 1;
            transition: opacity 0.4s ease-in-out 1s
        }

    }


    section {
        &#cards_text_home_1 {
            transition: all 0.4s ease-in-out 1.2s;
            transform: translateY(0);
            opacity: 1;
        }
    }
}
