
footer {
    padding: 32px 0;
    margin-top: 50px;
    position: relative;
    z-index: 1;
    background-color: white;

    @include media-breakpoint-up(lg){
        margin-top: 65px;
    }

    .footer__inner {
        
        .container {

            ul {
                display: flex;
                justify-content: space-between;
                list-style-type: none;
                padding: 0;
                margin: 0;
                flex-wrap: wrap;

              


                li {
                    font-size: 1.4rem;

                    @media screen and (max-width: 400px){
                        width: 50%;
                    }

                    a {
                        opacity: 0.68;
                        color: black;
                        transition: opacity 0.4s ease-in-out;
                    }

                    span {
                        opacity: 0.68;
                    }

                    &:hover {
                        a {
                            opacity: 1;
                        }
                    }


                    &.leeroy {
                        font-size: 1.1rem;
                        order: 6;
                        width: 100%;
                        text-align: center;
                        margin-top: 30px;

                        @include media-breakpoint-up(lg) {
                            width: auto;
                            margin-top: 10px;
                        }

                        a {
                            opacity: 0.5;
                            text-transform: uppercase;
                        }

                        &:hover {
                            a {
                                opacity: 1;
                            }
                        }

                    }

                    &.copyright {
                        order: 5;
                        width: 100%;
                        text-align: center;
                        margin-top: 70px;

                        @include media-breakpoint-up(lg) {
                            width: auto;
                            order: 0;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
