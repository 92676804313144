/**
 * Gilroy font
 */
@font-face {
    font-family: 'Gilroy-Bold';
    src: url('../../fonts/gilroy/Gilroy-Bold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/gilroy/Gilroy-Bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('../../fonts/gilroy/Gilroy-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/gilroy/Gilroy-Bold.svg') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../../fonts/gilroy/Gilroy-Regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/gilroy/Gilroy-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('../../fonts/gilroy/Gilroy-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/gilroy/Gilroy-Regular.svg') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Gilroy-Light';
    src: url('../../fonts/gilroy/Gilroy-Light.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/gilroy/Gilroy-Light.woff') format('woff'), /* Pretty Modern Browsers */
    url('../../fonts/gilroy/Gilroy-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/gilroy/Gilroy-Light.svg') format('svg'); /* Legacy iOS */
}


.gilroy--regular {
    font-family: 'Gilroy-Regular';
}
.gilroy--bold {
    font-family: 'Gilroy-Bold';
}
.gilroy--light {
    font-family: 'Gilroy-Light';
}
