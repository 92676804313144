.preloader {
        position: fixed;
        pointer-events: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        overflow: visible;
        background: transparent;
        z-index:99999;

        svg {
            display: none;
        }

        &.is--active {
            pointer-events: none;
            &:before {
                transform: scaleY(1);
                background: white;
                z-index: 1;
                transform-origin: center bottom;
                transition-duration: 0.6s;
                -ms-transform-origin: center bottom;
            }
    
        svg {
            display: block;
            height: 100px;
            position: relative;
            margin: auto;
            z-index: 100;
            animation: zoom 0.8s 4;
        } 
    }
}

@keyframes zoom {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}
