.prefooter {
    max-width: 960px;
    margin: -60px auto 0px;


    .container {

        // Animation section du bas
        &.animate {

            .bottom {
                opacity: 0;
                transform: translateY(-165px);
            }

            &.is-inview {

                .bottom {
                    transform: translateY(0);
                    opacity: 1;
                    overflow: visible;
    
                    .btn {
                        margin-top: 45px;
                        margin-bottom: -22px;
    
    
                        @include media-breakpoint-up(md) {
                            margin-bottom: 0;
                        }

                        @include media-breakpoint-only(lg) {
                            margin-top: 30px;
                            margin-bottom: 0;
                        }
    
                        @include media-breakpoint-up(xl) {
                            margin-top: 0;
                        }
    
                        @include media-breakpoint-up(xl) {
                            transform: translateX(120px);
    
                            /* IE11 */
                            @media all and (-ms-high-contrast:none)
                            {
                                transform: translateX(150px);
                            }
                        }
                    }
                }

            }
        }
    }


    .prefooter__inner {
        a {
            color: white;
            text-decoration: underline;
        }

        .big {
            font-size: 2rem;
            line-height: 1.5;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-size: 2.5rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 2.3rem;
            }
        }

        .small {
            font-size: 1.6rem;

        }


        .top {
            height: auto;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.22);
            background-color: #000000;
            color: white;
            position: relative;
            z-index: 2;
            padding: 30px;

            br {
                display: none;
            }

            @include media-breakpoint-up(md) {
                padding: 30px 50px 30px 70px;

                br {
                    display: block;
                }
            }

            @include media-breakpoint-up(xl){
                height: 165px;
            }


        }

        .bottom {
            position: relative;
            z-index: 1;
            margin-top: -15px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.22);
            background-color: #ffffff;
            transition: all 1.5s ease-in-out;
            padding: 60px 30px 0 30px;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            flex-direction: column;

            a {
                text-decoration: none;
            }

            @include media-breakpoint-up(md) {
                padding: 60px 50px 45px 70px;
                max-height: 190px;
            }

            @include media-breakpoint-up(xl) {
                flex-direction: row;
            }

            .btn {
                display:block;

                @include media-breakpoint-up(lg) {
                    transition: all 0.6s ease-in-out 0.2s;
                    transition-delay: 1.5s;
                }

                @include media-breakpoint-up(xl) {
                    width: auto;
                    position: absolute;
                    right: 0;

                    /* IE11 */
                    @media all and (-ms-high-contrast:none)
                    {
                       top:58px;

                    }
                }

            }

            .big {
                color: black;
                margin-bottom: 0;
                text-align: left;
                display: block;
                width: 100%;

                @include media-breakpoint-up(md) {
                    max-width: 70%;
                }
            }

        }
    }
}
