body[data-view="career"] {

    main {
        position: relative;
        overflow: hidden;
    }

    .bg__shape {
        position: absolute;
        top: 510px;
        left: 0;
        height: calc(100% - 1250px);
        width: auto;
        display: none;

        @media screen and (orientation: landscape) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            left: -260px;
            height: calc(100% - 1080px);
        }

        @include media-breakpoint-up(xl) {
            left: -70px;
            height: calc(100% - 1165px);
        }

        @include media-breakpoint-up(laptop) {
            height: calc(100% - 1400px);
        }

        @include media-breakpoint-up(hd) {
            height: calc(100% - 1350px);
        }

    }


    section {

        &.hero__banner {
            position: relative;
            z-index: 0;

            .hero__banner__inner {
                background-position: center;
            }
        }


        &.plain__text {
            position: relative;
            margin: 0 auto 50px;
            padding-top: 100px;

            .plain__text__inner {
                position: relative;
                z-index: 2;
                margin-top: 60px;

                h3 {
                    @include media-breakpoint-up(hd) {
                        font-size: 4.5rem;
                    }
                }

                @include media-breakpoint-up(xl) {
                    margin-top: 0;
                }
            }


            .section__title {
                margin-bottom: 40px;
                text-align: left;

                @include media-breakpoint-up(md) {
                    margin-bottom: 60px;
                }
            }

            p {
                line-height: 1.88;
                font-size: 1.6rem;
            }
        }

        &.cards__text {
            margin-bottom: 50px;

            @include media-breakpoint-up(xl) {
                margin-bottom: 100px;
            }


            .pictures--left {
                z-index: 1;
                left: 0;

                .pictures {

                    @include media-breakpoint-up(lg) {
                        margin-left: -20px;
                    }
                }

            }

            .pictures--right {
                z-index: 1;
                right: 0;

                .pictures {

                    @include media-breakpoint-up(lg) {
                        margin-right: -20px;
                    }
                }

            }
        }

        &#cards_text_career_1 {
            position: relative;
            margin-bottom: 0;

            .cards__text__inner {
                &::after {
                    content: url("../../../images/layout/losange.svg");
                    position: absolute;
                    right: 0px;
                    top: 40%;
                    width: 300px;
                    z-index: 0;
                    transform-origin: right;
                    transform: scale(0.9);
    
                    animation: translateLeft 1s forwards;
    
                    @include media-breakpoint-down(sm) {
                        top: -20%;
                        transform: scale(0.7);
                    }
                }
            }
        }

        &#cards_text_career_2 {
            padding-top: 0;

            @include media-breakpoint-up(lg) {
                padding-top: 110px;
                margin-bottom: 100px;
            }
        }

        &#career_types {
            .icon__text__inner {
                position: relative;
                z-index: 2;

                > .container {

                    > .row {
                        max-width: 1200px;
                        justify-content: center;
                        flex-wrap: wrap;
                        margin: 0 auto;

                        @include media-breakpoint-up(md) {
                            justify-content: center;
                        }


                        @include media-breakpoint-up(xl) {
                            justify-content: space-between;
                        }

                    }

                }

                .icon__text__tile {
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: center;
                    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.14);
                    border-radius: 10px;
                    padding: 25px;
                    margin-bottom: 66px;

                    width: 90%;
                    height: auto;

                    @include media-breakpoint-up(md) {
                        margin: 15px;
                        width: 310px;
                    }
                    @include media-breakpoint-up(lg) {
                        margin: 20px;
                        width: 300px;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 40px;
                        width: 325px;
                    }
                    @include media-breakpoint-up(laptop) {
                        width: 360px;
                    }

                    i {
                        font-size: 6rem;
                        color: $red;
                        height: auto;
                    }

                    h3 {
                        font-size: 2.5rem;
                        margin-bottom: 12px;
                        line-height: 1;
                    }
                    p {
                        max-width: 100%;
                    }
                }
            }
        }

        &#text_background_career_1 {

            .text__background__inner {
                padding: 50px 0 100px;

                @include media-breakpoint-up(lg) {
                    padding: 80px 0 130px;
                }

                @include media-breakpoint-up(laptop) {
                    padding: 220px 0 220px;
                }
            }

            .content {
                padding: 35px 30px;
                width: 90%;
                margin: 0 auto;

                @include media-breakpoint-up(md) {
                    padding: 65px 85px;
                    max-width: 850px;
                    width: 100%;
                }


                @include media-breakpoint-up(lg) {
                    padding: 75px 125px;
                    max-width: 850px;
                    width: 100%;
                }

                p {
                    font-size: 1.6rem;
                    line-height: 1.88;

                    a{
                        font-weight:600;
                    }

                }

                .section__title {
                    margin-bottom: 20px;
                }

                // form {
                //     margin-top: 50px;
                //     display: flex;
                //     flex-direction: column;

                //     @include media-breakpoint-up(md) {
                //         flex-direction: row;
                //         justify-content: space-between;
                //     }
                // }

                #submit_career {
                    color: white;
                    width: 100%;
                    margin-top: 20px;

                    span {
                        width: 100%;
                    }

                    @include media-breakpoint-up(md) {
                        max-width: 200px;

                        span {
                            max-width: 200px;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .popup-video {
            font-size: 1.6rem;
            font-weight: 600;
            color: #09637f;
            cursor: pointer;
        }

        #popup {
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            margin: auto;
            z-index: 999;

            &.hide {
                display: none;
            }

            .close-modal {
                top: 1.5rem;
                position: absolute;
                right: 1.5rem;
                transform: none;
                color: #8a8a8a;
                cursor: pointer;
                background: none;
                border: none;
                z-index: 9999;

                span {
                    svg {
                        width: 25px;
                        height: 25px;
                        fill:#8a8a8a;
                    }
                }
            }

            .video-container {
                position: absolute;
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                overflow: hidden;

                &:before {
                    content: "";
                    background: rgba(0,0,0,0.4);
                    position: absolute;
                    left: 0;
                    top:0;
                    width: 100%;
                    height: 100%;
                    z-index:1;
                }

                iframe {
                    width: 100vw;
                    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
                    min-height: 100vh;
                    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

/* ANIMATIONS */

body.animate {
    #top_cta_home {
        .container {
            transform: translateY(42.5px);
            max-width: 350px;
            opacity: 0;
            height: 85px;
            overflow: hidden;

            .top__cta--contact {
                opacity: 0;
            }
        }
    }

    section {
        &#cards_text_home_1 {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}

/* ANIMATIONS */

body.animate {
    .bg__shape {
        opacity: 0;
        transform: translateX(-20%);
    }
    #plain_text_career_1 {
        opacity: 0;
        transform: translateY(50px);
    }
}

body:not(.animate) {

    .bg__shape {
        opacity: 1;
        transform: translateX(0);
        transition: all 1s ease-in-out 2s,
    }

    #plain_text_career_1 {
        opacity: 1;
        transform: translateY(0);
        transition: all 1s ease-in-out 2s,
    }
}
