section {
    &.hero__banner {
        position: relative;
        z-index: 0;
        background-color: grey;

        .hero__banner__inner {
            background-position: center;
        }
    }

    &.plain__text {
        position: relative;
        padding-top: 100px;
        padding-bottom:100px;
     
        .plain__text__inner {
            position: relative;
            z-index: 2;

            h3 {
                @include media-breakpoint-up(hd) {
                    font-size: 4.5rem;
                }
            }

            @include media-breakpoint-up(xl) {
                margin-top: 15;
                padding-top: 15px;
            }
        }

        .section__title {
            margin-bottom: 40px;
            text-align: left;

            @include media-breakpoint-up(md) {
                margin-bottom: 60px;
            }
        }

        p {
            line-height: 1.88;
            font-size: 1.6rem;
        }

    }
}
