.icon__text__tile {
    width: 310px;
    border-radius: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.13);
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    margin-bottom: 60px;
    align-items: center;


    @include media-breakpoint-up(sm) {
        width: 340px;
    }

    @include media-breakpoint-up(lg) {
        width: 360px;
        margin: 30px;
    }

    @include media-breakpoint-up(xl) {
        width: 360px;
        margin: 0 0 30px;
    }

    @include media-breakpoint-up(laptop) {
        margin: 0 0 60px;
    }


    i {
        color: red;
        font-size: 4.5rem;
        height: 50px;
        margin-left: -6px;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }
    }

    h3 {
        font-size: 1.7rem;
        line-height: 1.56;
        margin-bottom: 0;
        width: 75%;

        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
    }

}


// Manage on "per-page" exception
.icon__text__list {
    .cta.mobile {
        display: none;
    }
}
