
.btn,
.button {
    display:block;
    width: 240px;
    height: 50px;
    border-radius: 25px;
    line-height:50px;
    color:white;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;

    span{
        border-radius: 25px;
        width: 240px;
        height: 50px;
        transition: all 0.6s ease;
        @extend .gilroy--bold;
        letter-spacing:0.5px;
        font-size:1.4rem;
        text-align:center;
        display:block;

        @include media-breakpoint-up(md){
            font-size: 1.6rem;
        }
    }

    &--long{
        width: 300px;

        span{
            width: 300px;
        }

        @include media-breakpoint-up(md){
            width: 380px;

            span{
                width: 380px;
            }
        }
    }

    &--arrow{

        i{
            display:inline-block;
            top:4px;
            font-size:1.6rem;
            margin-left:3px;
            transform: translateX(5px);
            position:relative;

            @include media-breakpoint-up(md){
                margin-left: 13px;
                margin-right: -13px;
                top:4px;
                font-size:2rem;
                transform: translateX(0);
            }

        }

        &:hover{
            i{
                animation: arrow-move-lr 1s;
                animation-iteration-count: infinite;
            }
        }
    }

    &--red{
        background-image: linear-gradient(to left, #f44751, #e52228);
        span{
            background-color:$red;
        }

        &:hover{
            color: white;
            span{
                background-color:transparent;
            }
        }

        &.disabled{
            background-color: rgba(229, 34, 40, 0.5);
        }
    }

    &--teal{
        background-image: linear-gradient(to left, #085168, #09637f);
        span{
            background-color:$teal;
        }

        &:hover{
            color: white;
            span{
                background-color:transparent;
            }
        }

        &.disabled{
            background-color: rgba(#09637f, 0.5);
        }
    }


    &--darkGrey{
        background-image: linear-gradient(to left, #6c7077, #3a3d42);
        span{
            background-color:$darkGrey;
        }

        &:hover{
            color: white;
            span{
                background-color:transparent;
            }
        }

        &.disabled{
            background-color: rgba(229, 34, 40, 0.5);
        }
    }
}



/* Standard syntax */
@keyframes arrow-move-lr {
    0%{
        opacity:0;
        transform: translateX(0px);
    }
    25%{
        opacity:0.25;
        transform: translateX(2px);
    }
    50%{
        opacity:0.5;
        transform: translateX(4px);
    }
    75%{
        opacity:0.75;
        transform: translateX(6px);
    }
    100%{
        opacity:1;
        transform: translateX(8px);
    }
}


@keyframes arrow-move-ud {
    0%{
        opacity:0;
        transform: translateY(-4px);
    }
    25%{
        opacity:0.25;
        transform: translateY(-2px);
    }
    50%{
        opacity:0.5;
        transform: translateY(0px);
    }
    75%{
        opacity:0.75;
        transform: translateY(2px);
    }
    100%{
        opacity:1;
        transform: translateY(4px);
    }
}
