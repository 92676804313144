/**
 * Responsive Mixins for BOOTSTRAP

    Gap is 30

    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    laptop: 1439px,
    hd : 1920px,
    huge: 2000px

*/

.col-no-padding-sm{
    padding-left:0;
    padding-right:0;

    @include media-breakpoint-up(md){
        padding-left:$grid-gutter-width/2;
        padding-right:$grid-gutter-width/2;
    }
}

.CSS-debugbar {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    text-align: center;
    width: 100px;
    padding: 5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);

    display:none; // for prod

    &:after {
        font-weight: normal;
        font-size: 12px;
        mix-blend-mode: difference;

        @include media-breakpoint-up(xs) {
            content: "xs";
        }
        @include media-breakpoint-up(sm) {
            content: "sm";
        }
        @include media-breakpoint-up(md) {
            content: "md";
        }
        @include media-breakpoint-up(lg) {
            content: "lg";
        }
        @include media-breakpoint-up(xl) {
            content: "xl";
        }
        @include media-breakpoint-up(laptop) {
            content: "laptop";
        }
        @include media-breakpoint-up(hd) {
            content: "hd";
        }
        @include media-breakpoint-up(huge) {
            content: "huge";
        }

    }
}
