.text__background {
    width: 100%;
    display: flex;

    &__inner {
        background-size: cover !important;
        width: 100%;
        height: 100%;
        padding: 100px 0 150px;

        .content {
            background-color: white;
            border-radius: 10px;
            color: black;

            .section__title {
                margin-bottom: 40px;
                font-size: 3rem;
                line-height: normal;

                @include media-breakpoint-up(md) {
                    font-size: 4rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 4.5rem;
                }


                @include media-breakpoint-up(md) {
                    margin-bottom: 60px;
                }
            }

        }
    }
}

