body[data-view="services"] {
    background-color: #F6F6F6;
    #topcta {
        height: 85px;

        .top__cta__inner {
            .container {
                > .row {
                    justify-content: flex-end;

                    .top__cta__wrapper {
                        i {
                            &.promark-icon-truck {
                                /* Background blanc quand on passe sur une section colorée */
                                &.new-bg {
                                    span.bg {
                                        background-color: white;
                                    }

                                    &:before {
                                        color: black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section {

        &.icon__text__list {
            height: auto;
            padding: 200px 0 100px;

            .icon__text__tile {
                h3 {
                    font-weight: normal;
                    @extend .gilroy--regular;
                }
            }

            .progress {
                height: 50px;
                position: absolute;
                left: 20px;
                z-index: 5;
                top: -80px;
                //display:none !important; /* Jira PW-106 */

                @include media-breakpoint-up(xl) {
                    left: 0;
                    top: -80px;
                }

                .nb {
                    font-size: 1.4rem;
                    position: absolute;
                    right: -22px;
                    top: -5px;
                    @extend .gilroy--bold;
                }

                .bar {
                    position: relative;
                    height: 50px;
                    width: 1px;
                    background-color: rgba(0, 0, 0, 0.3);

                    &::after {
                        height: 50%;
                        content: "";
                        width: 1px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: black;
                    }
                }
            }

            .container {
                position: relative;
                z-index: 2;


                .row {
                  
                    justify-content: center;
                    flex-wrap: wrap;
                    padding:0 20px;
 

                    .icon__text__tile{
                        margin: 20px;
                    }

            


                }
            }
        }

        &#services_banner{
            z-index: 3;

            p{
                max-width: 320px;
                line-height:24px;
            } 
        }

        &#services_private_locating,
        &#services_public_locating,
        &#services_dedicated_locators,
        &#services_training_audit {
            z-index: 2;
            position: relative;
            margin-top: -85px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 100px 0 40px 0;

            @include media-breakpoint-up(sm) {
                padding: 170px 0 100px;
            }


            .bg__shape {
                position: absolute;
                top:30%;
                right: 0;
                width: 300px;
                transform: none !important;
                transition: none !important;
 

                @include media-breakpoint-up(xl) {
                    top:-10%;
                    width: auto;
                }

           
            }

            .icon__text__inner {
                width: 100%;

                .container {
                    width: 100%;
                    max-width: 1260px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;

                    @include media-breakpoint-up(xl) {
                        flex-direction: row;
                    }


                    .cta.mobile {
                        display: block;
                        order: 3;
                        margin-top: 30px;

                        @include media-breakpoint-up(xl) {
                            display: none;
                        }
                    }
                }
            }


            .text__content {
                max-width: 360px;
                padding:0 20px;

                @include media-breakpoint-up(xl) {
                    order: 1;
                }


                .section__title {
                    text-align: center;
                    max-width: 80%;
                    margin: 0 auto 20px;
                    color:$teal;

                    @include media-breakpoint-up(xl) {
                        text-align: left;
                        max-width: none;
                        margin: 0 0 20px;
                    }

                }

                .section__text {
                    margin-bottom: 40px;
                    text-align: center;

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 20px;
                        text-align: left;
                    }

                    p {
                        font-size: 16px;
                    }
                }

                a {
                    display: none;

                    @include media-breakpoint-up(xl) {
                        display: block;
                    }
                }
            }

            .row {
                order: 1;

                @include media-breakpoint-up(md) {
                    // max-width: 640px;
                }

                @include media-breakpoint-up(xl) {
                    order: 2;
                }

                @include media-breakpoint-up(lg) {
                    // max-width: 760px;
                }

                @include media-breakpoint-up(xl) {
                    // max-width: 700px;
                }
                @include media-breakpoint-up(laptop) {
                    // max-width: 760px;
                }
            }


            .icon__text__tile {
      

                @include media-breakpoint-up(md) {
                    margin-bottom: 4rem;
                }

                @include media-breakpoint-up(xl) {
                    width: 330px;
                    height: 190px;
                    max-width: none;
                }

                @include media-breakpoint-up(laptop) {
                    width: 360px;
                }

                i {
                    margin-bottom: 1rem;
                }

                h3 {
                    width: 80%;
                    text-align: left;
                    margin-left: 30px;
                }

            }

        }

        &#services_public_locating {
            .bg__shape {
                position: absolute;
                top:30%;
                left: 0;
                width: 300px;
                transform: none !important;
                transition: none !important;


                @include media-breakpoint-up(xl) {
                    top:0%;
                    width: auto;
                }
            }

            .text__content {
                .section__title {
                    color: $red;
                }
            }

            .icon__text__inner {
                .container {
                    @include media-breakpoint-up(xl) {
                        flex-direction: row-reverse;
                    }
                }
            }
        }


        #services_dedicated_locators {
            .bg__shape {
                position: absolute;
                top:30%;
                right: 0;
                width: 300px;
                transform: none !important;
                transition: none !important;


                @include media-breakpoint-up(xl) {
                    top:0%;
                    width: auto;
                }
            }

            .text__content {
                .section__title {
                    color: $blue;
                }
            }

            .icon__text__inner {
                .container {
                    @include media-breakpoint-up(xl) {
                        flex-direction: row;
                    }

                    .icon__text__tile{
                        h3{
                            margin:0 0 0 30px;
                        }
                        .icon{
                            img{
                                width: 70px;
                                height:auto;
                            }
                        }
                    }
                }
            }
        }


        &#services_training_audit{
            .bg__shape {
                position: absolute;
                top:30%;
                left: 0;
                width: 300px;
                transform: none !important;
                transition: none !important;
                opacity:0.15;


                @include media-breakpoint-up(xl) {
                    top:0%;
                    width: auto;
                }
            }

            .text__content {
                .section__title {
                    color: #231F20;
                }
            }

            .icon__text__inner {
                .container {
                    @include media-breakpoint-up(xl) {
                        flex-direction: row-reverse;
                    }

                    .icon__text__tile{
                  
                        .icon{
                            img{
                                width: 70px;
                                height:auto;
                            }
                        }
                    }
                }

            }
        }


        #services_underground_infrastructure{
          
                z-index: 1;
                position: relative;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 100px 0 60px;
    
    
                @include media-breakpoint-up(md) {
                    padding-bottom: 150px;
                }
    
                @include media-breakpoint-up(lg) {
                    padding-bottom: 200px;
                }

                .row{
                    max-width: 1260px;
                }
    
    
                .icon__text__inner {

                    .section__title {
                        color: #231F20;
                        text-align: center;
                        max-width: 80%;
                        margin: 0 auto 60px;
                    }

                    .icon__text__tile{
                        background-color: #F6F6F6;
                    }
    
                }
            
        }

        &.prefooter {
            z-index: 3;
            position: relative;
            margin-top: 80px;

            @include media-breakpoint-up(md) {
                margin-top: -100px
            }
        }
    }
}


body.animate {
    #top_cta_services {
        .container {
            transform: translateY(42.5px);
            max-width: 350px;
            opacity: 0;
            height: 85px;
            overflow: hidden;

            .top__cta--contact {
                opacity: 0;
            }
        }
    }

    section {
        &#services_list_block1 {
            opacity: 0;
            transform: translateY(50px);
        }

        &#services_list_block1_b {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}


body:not(.animate) {

    #top_cta_services {
        .container {
            opacity: 1;
            width: auto;
            height: auto;
            overflow: visible;
            transform: translateY(0);
            transition: opacity 0.4s ease-in-out 0.2s,
            transform 0.4s ease-in-out 0.4s,
            max-width 0.4s ease-in-out 0.6s,
            height 0.4s ease-in-out 0.8s;

        }

        .top__cta--contact {
            opacity: 1;
            transition: opacity 0.4s ease-in-out 1s
        }
    }

    section {
        &#services_list_block1 {
            transition: all 0.4s ease-in-out 1s;
            transform: translateY(0);
            opacity: 1;
        }

        &#services_list_block1_b {
            transition: all 0.4s ease-in-out 1s;
            transform: translateY(0);
            opacity: 1;
        }
    }
}
