body[data-view="technology"] {

    #main {
        position: relative;
        overflow: hidden;
    }

    .hero__banner {
        height: 540px;
    }

    .top__cta {
        .top__cta__inner {
            .container {

                > .row {
                    justify-content: flex-end;

                }
            }
        }
    }

    section {
        z-index: 3;
        position: relative;


        &#cards_text_technology_1 {
            z-index: 5;
            overflow: hidden;
            @include media-breakpoint-up(xl) {
                .pictures {
                    margin-top: -80px;
                }

                .content {
                    margin-top: -50px;
                }
            }
        }


        &.cards__text {
            pointer-events: none;


            .row {
                align-items: center;
            }

            .cards__text__inner {

                .container.pictures--right {
                    .row {
                        div:nth-of-type(1) {
                            order: 2;

                            @include media-breakpoint-up(lg) {
                                order: 1;
                            }
                        }

                        div:nth-of-type(2) {
                            order: 1;

                            @include media-breakpoint-up(lg) {
                                order: 2;
                            }
                        }

                        .pictures,
                        .content {
                            transform: none;
                        }
                    }
                }


                .content {


                    margin-top: 60px;

                    @include media-breakpoint-up(lg) {
                        margin-top: 0px;
                    }


                    .section__title {
                        margin-bottom: 20px;

                        @include media-breakpoint-up(md) {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }

        &.plain__text {
            max-width: 600px;
            margin: 100px auto 200px;

            .section__title {
                margin-bottom: 40px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 60px;
                }
            }

            p {
                line-height: 2.14;
                font-size: 1.4rem;
            }
        }
    }
}


/* Lines */

.line {
    height: 230px;
    width: 10px;
    cursor: none;
    position: absolute;
    pointer-events: none;
    z-index: 0;
    opacity:1;
    transition: opacity 2s ease-in;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    @include media-breakpoint-up(laptop) {
        pointer-events: auto;
        height: 540px;
    }

    @include media-breakpoint-up(hd) {
        pointer-events: auto;
        height: 630px;
    }

    &--blue {
        background-image: url("../../../images/lines/line-blue.png");
    }

    &--orange {
        background-image: url("../../../images/lines/line-orange.png");
    }

    &--red {
        background-image: url("../../../images/lines/line-red.png");
    }

    &--yellow {
        background-image: url("../../../images/lines/line-yellow.png");
    }

    &--green {
        background-image: url("../../../images/lines/line-green.png");
    }

    &--pink {
        background-image: url("../../../images/lines/line-pink.png");
    }

    &--purple {
        background-image: url("../../../images/lines/line-purple.png");
    }

    /* individual lines positioning */

    /* red*/
    &.line1 {
        left: 1%;
        top: 8%;
        z-index: 4;
        height: 632px;

        @include media-breakpoint-only(md) {
            left: 1%;
            top: 5%;
        }

        @include media-breakpoint-up(lg) {
            left: 1%;
            top: 8%;
        }

        @include media-breakpoint-up(xl) {
            left: 1%;
            top: 7%;
        }

        @include media-breakpoint-up(laptop) {
            left: 3%;
        }
    }

    /* green */
    &.line2 {
        left: 3%;
        top: 10%;
        height: 472px;
        
        @include media-breakpoint-only(md) {
            left: 5%;
            top: 8%;
        }

        @include media-breakpoint-up(xl) {
            left: 3%;
            top: 11%;
        }

        @include media-breakpoint-up(laptop) {
            left: 9%;
        }
    }

    /* pink */
    &.line3 {
        right: 17%;
        top: 7%;
        z-index: 4;
        height: 632px;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            right: 5%;
            top: 3%;
        }

        @include media-breakpoint-up(hd) {
            right: 11%;
            top: 5%;
        }
    }

    /* yellow */
    &.line4 {
        right: 11%;
        top: 8%;
        z-index: 4;
        height: 539px;

        @include media-breakpoint-only(md) {
            right: 5%;
            top: 5%;
        }

        @include media-breakpoint-up(lg) {
            right: 3%;
            top: 7%;
        }

        @include media-breakpoint-up(hd) {
            right: 8%;
        }
    }

    /* blue */
    &.line5 {
        right: 5%;
        top: 6.5%;
        z-index: 4;
        height: 630px;

        @include media-breakpoint-only(md) {
            right: 1%;
            top: 1.5%;
        }

        @include media-breakpoint-up(lg) {
            right: 1%;
            top: 3%;
        }

        @include media-breakpoint-up(hd) {
            right: 5%;
        }
    }


    /* red */
    &.line6 {
        right: 30%;
        top: 19%;
        height: 539px;

        @include media-breakpoint-only(md) {
            top: 20%;
        }

        @include media-breakpoint-up(lg) {
            top: 25%;
            right: 35%;
        }
    }

    /* blue */
    &.line7 {
        left: 6%;
        top: 28%;
        height: 539px;

        @include media-breakpoint-only(md) {
            top: 32%;
        }

        @include media-breakpoint-up(lg) {
            left: 1%;
            top: 30%;
        }

        @include media-breakpoint-up(hd) {
            left: 4%;
        }
    }

    /* purple */
    &.line8 {
        left: 15%;
        top: 38%;
        height: 466px;
    

        @include media-breakpoint-only(md) {
            top: 45%;
        }

        @include media-breakpoint-up(lg) {
            left: 3%;
            top: 35%;
        }
    }

    /* orange */
    &.line9 {
        left: 19%;
        top: 28.5%;
        height: 400px;

        @include media-breakpoint-only(md) {
            top: 33%;
        }
        
        @include media-breakpoint-up(lg) {
            left: 8%;
            top: 35%;
        }
    }

    /* blue */
    &.line10 {
        right: 4%;
        top: 38.5%;
        height: 607px;

        @include media-breakpoint-only(md) {
            top: 47%;
        }

        @include media-breakpoint-up(lg) {
            right: 5%;
            top: 38%;
        }

        @include media-breakpoint-up(laptop) {
            right: 10%;
            top: 41%;
        }

        @include media-breakpoint-up(hd) {
            right: 15%;
        }
    }

    /* green */
    &.line11 {
        right: 19%;
        top: 47.5%;
        height: 466px;

        @include media-breakpoint-only(md) {
            top: 56%;
        }
        
        @include media-breakpoint-up(xl) {
            right: 15%;
            top: 69%;
        }
    }

    /* red */
    &.line12 {
        right: 15%;
        top: 57.5%;
        height: 623px;

        @include media-breakpoint-up(lg) {
            right: 18%;
            top: 46%;
        }

        @include media-breakpoint-up(xl) {
            right: 18%;
        }

        @include media-breakpoint-up(laptop) {
            right: 18%;
        }

        @include media-breakpoint-up(hd) {
            right: 18%;
        }
    }

    /* yellow */
    &.line13 {
        left: 9%;
        top: 76%;
        height: 632px;

        @include media-breakpoint-up(lg) {
            left: 6%;
            top: 82%;
        }
        @include media-breakpoint-up(laptop) {
            top: 78%;
        }
    }

    /* blue */
    &.line14 {
        left: 17%;
        top: 68.5%;
        height: 453px;

        @include media-breakpoint-only(md) {
            top: 69%;
        }

        @include media-breakpoint-up(lg) {
            left: 12%;
            top: 80%;
        }
    }

    /* pink */
    &.line15 {
        right: 10%;
        top: 94%;
        height: 632px;

        @include media-breakpoint-up(lg) {
            right: 15%;
            top: 90%;
        }

        @include media-breakpoint-up(xl) {
            right: 22%;
        }

        @include media-breakpoint-up(laptop) {
            right: 20%;
        }
    }

    /* yellow */
    &.line16 {
        right: 6%;
        top: 96%;
        height: 453px;

        @include media-breakpoint-up(lg) {
            right: 12%;
            top: 93%;
        }

        @include media-breakpoint-up(xl) {
            right: 18%;
            top: 97%;
        }
        @include media-breakpoint-up(laptop) {
            right: 28%;
        }
    }
}


#custom__cursor {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    cursor: none;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    overflow: hidden;

    &.show {
        opacity: 1;
        overflow: visible;

        .text {
            padding: 20px;
            width: 335px;
        }
    }

    .shape {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        pointer-events: none;
        cursor: none;
    }

    .text {
        background-color: white;
        color: black;
        font-size: 1.6rem;
        font-weight: 600;
        border-radius: 10px;
        position: absolute;
        top: -18px;
        text-align: center;

        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);


        /* Direction de la bulle texte */
        &.right {
            left: auto;
            right: -360px;
        }

        &.left {
            right: auto;
            left: -360px;
        }

    }
}


/* ANIMATIONS */

body.animate {
    #top_cta_technology {
        .container {
            transform: translateY(42.5px);
            max-width: 350px;
            opacity: 0;
            height: 85px;
            overflow: hidden;

            .top__cta--contact {
                opacity: 0;
            }
        }
    }

    section {
        &#cards_text_technology_1 {
            opacity: 0;
            transform: translateY(50px);
        }
    }

    .line {
        opacity: 0;
    }
}


body:not(.animate) {

    #top_cta_technology {
        .container {
            opacity: 1;
            width: auto;
            height: auto;
            overflow: visible;
            transform: translateY(0);
            transition: opacity 0.4s ease-in-out 0.2s,
            transform 0.4s ease-in-out 0.4s,
            max-width 0.4s ease-in-out 0.6s,
            height 0.4s ease-in-out 0.8s;

        }

        .top__cta--contact {
            opacity: 1;
            transition: opacity 0.4s ease-in-out 1s
        }

    }


    section {
        &#cards_text_technology_1 {
            transition: all 0.4s ease-in-out 1.2s;
            transform: translateY(0);
            opacity: 1;
        }
    }
}