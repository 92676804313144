.contact__cta {

    .content {
        background-color: $red;
        color: white;
        font-size: 3.5rem;
        border-radius: 10px;

        max-width: 915px;
        width: 100%;
        margin: 0 auto;
        padding: 32px 70px;

        a{
            color:white;
            text-decoration: underline;
        }

        .big {
            font-size: 2rem;
            line-height: 1.5;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                line-height:1.29;
                font-size: 3.5rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 3.5rem;
            }
        }

        .small {
            margin-top: 15px;
            p{
                font-size: 2rem;
                line-height:1.3;
            }

        }
    }

}