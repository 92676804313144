/**
 * Global settings
 * Might contain code to be displaced
 */

* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html {
    min-width: 320px;
    font-size: 62.5%; // base 10
    // scroll-behavior: smooth;
    background-color: #fff;
}

body {
    @extend .gilroy--regular;
}

main{
    overflow-x:hidden;
}


/* Links */

a,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

/* Text */

p {
    font-size: 1.4rem;
    line-height: 1.86;

    @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        line-height: 1.63;
    }

    a{
        color:$teal;
    }
}

.underlined {
    text-decoration: underline;
}


/* Headings */

h1, h2, h3, h4, h5 {
    line-height: 1;
    font-weight: normal;
}

h1 {
    font-size: 4rem;
    line-height: 1.25;
    @extend .gilroy--bold;

    @include media-breakpoint-up(md) {
        font-size: 5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 7rem;
    }
}

h2 {
    @extend .gilroy--bold;

    &.section__title {
        font-size: 3rem;

        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }
    }
}


h3 {
    @extend .gilroy--bold;

    &.section__title {
        font-size: 3rem;

        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }
    }
}

/* Forms */

/* Slim Select - https://slimselectjs.com/ */
.ss-main {
    max-width: 340px;
    width: 100%;
    font-size: 1.4rem;

    .ss-single-selected {
        border-radius: 25px;
        height: 50px;
        color: #2b2b2b;
        padding-left: 25px;
        border:1px solid #979797;

        .ss-arrow {
            padding-right: 12px;

            &::before{
                content: "";
                width:1px;
                height:22px;
                margin-right:12px;
                background-color: #e3e8eb;
            }
        }

    }
}



/* Animation stuff */
.move{
    position:relative;
    transform: translateY(0px);
   /* transition:all 1s cubic-bezier(.17,.67,.62,.52);*/

    /* Utilise des margin-top pour pas overrider Reveal + scrollmagic */

    &[data-offset-sm="-60"]{
        margin-top:-60px;
    }
    &[data-offset-sm="-50"]{
        margin-top:-50px;
    }
    &[data-offset-sm="-40"]{
        margin-top:-40px;
    }
    &[data-offset-sm="-30"]{
        margin-top:-30px;
    }
    &[data-offset-sm="-20"]{
        margin-top:-20px;
    }
    &[data-offset-sm="-10"]{
        margin-top:-10px;
    }

    &[data-offset-sm="60"]{
        margin-top:60px;
    }
    &[data-offset-sm="50"]{
        margin-top:50px;
    }
    &[data-offset-sm="40"]{
        margin-top:40px;
    }
    &[data-offset-sm="30"]{
        margin-top:30px;
    }
    &[data-offset-sm="20"]{
        margin-top:20px;
    }
    &[data-offset-sm="10"]{
        margin-top:10px;
    }


    @include media-breakpoint-up(lg){
        &[data-offset-lg="-150"]{
            margin-top:-150px;
        }
        &[data-offset-lg="-100"]{
            margin-top:-100px;
        }
        &[data-offset-lg="-50"]{
            margin-top:-50px;
        }
        &[data-offset-lg="-40"]{
            margin-top:-40px;
        }
        &[data-offset-lg="-30"]{
            margin-top:-30px;
        }
        &[data-offset-lg="-20"]{
            margin-top:-20px;
        }
        &[data-offset-lg="-10"]{
            margin-top:-10px;
        }


        &[data-offset-lg="150"]{
            margin-top:150px;
        }
        &[data-offset-lg="100"]{
            margin-top:100px;
        }
        &[data-offset-lg="50"]{
            margin-top:50px;
        }
        &[data-offset-lg="40"]{
            margin-top:40px;
        }
        &[data-offset-lg="30"]{
            margin-top:30px;
        }
        &[data-offset-lg="20"]{
            margin-top:20px;
        }
        &[data-offset-lg="10"]{
            margin-top:10px;
        }
    }
}


/* IE FIX FOR SCROLLREVEAL */
body.isIE {

    .reveal, .reveal-prefooter {
        opacity:1 !important;
    }
    
}

// .grecaptcha-badge { visibility: hidden; }