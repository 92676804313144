/**
 * Variables
 */

/* COLORS */

$gray: #616161;
$richBlack: #3e3e3e;
$darkGrey: #3a3d42;
$red: #e52228;
$teal:#09637f;
$blue:#759FAA;
