/**
 * --------------------------------------------------------
 *                        Promark
 * --------------------------------------------------------
 *
 * CONTENTS
 *
 * 01-VENDORS
 * Vendor libraries..........Vendor libraries included.
 *
 * 02-TOOLS
 * Breakpoints...............Responsive breakpoints.
 * Mixins....................Mixins. Just mixins.
 *
 * 03-SETTINGS
 * Variables.................Global variables and config.
 * Typography................Typography styling.
 *
 * 04-VIEWS
 * Pages.....................Pages specific styling.
 * Layouts...................Layout specific styling.
 * Components................Component specific styling.
 *
 * 05-EFFECTS
 * ..........................All the website effects.
 *
 */

@import '01-Vendors/loader';
@import '02-Tools/loader';
@import '03-Settings/loader';
@import '04-Effects/loader';
@import '05-Views/loader';
@import '06-Fonts/loader';
