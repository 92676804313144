#popup-brochure {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background: rgba(0,0,0,0.4);
  z-index: 10;

  
  .popup-wrapper {
    width: 90%;
    max-width: 500px;
    padding: 5rem 2rem;
    background-color: #fefefe;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 1rem;
    text-align: center;

    .popup-content {
      display: block;
      position: relative;
      top: 2rem;
      
      h2 {
        margin-bottom: 3rem;
        font-size: 2.5rem;
      }
    }
  }

  .close-popup {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  
  input[type="email"] {
    width: 240px;
    height: 45px;
    border-radius: 5px;
    line-height: 1;
    border: solid 1px #374448;
    font-size: 1.4rem;
    padding: 0 14px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }

  input[type="submit"] {
    border: none;
    font-size: 1.6rem;
    font-family: 'Gilroy-Bold';
    margin: 0 auto;
    background-color: #E8282F;
    transition: background-color 0.5s ease;
    margin-bottom: 1rem;

    &:hover {
      background-color: #F1404A;
    }
  }

  a.btn-download {
    display: none;
    font-size: 1.6rem;
    font-family: 'Gilroy-Bold';
    margin: 0 auto;
    background-color: #E8282F;
    transition: background-color 0.5s ease;

    &:hover {
      color: white;
      background-color: #F1404A;
    }
  }
}