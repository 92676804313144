body[data-view="P404"] {

    #plain_text_404 {
        margin: 95px auto;

        .section__title {
            text-align: center;
            z-index: 1;
        }

        p {
            text-align: center;
            font-size: 2rem;
        }

        a {
            margin: 50px auto;
        }

    }

    .line {
        z-index: 999;
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
}

body.animate {

    #plain_text_404  {
        transform: translateY(42.5px);
        opacity: 0;
        overflow: hidden;
    }

}

body:not(.animate) {
    #plain_text_404 {
        transform: translateY(0);
        opacity: 1;
        transition: all 1s ease-in-out 1.5s;
    }

}