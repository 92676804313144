.nb__jobs {
    .nb__jobs__inner {

        > .container > .row {
            justify-content: center;

            @include media-breakpoint-up(xl) {
                justify-content: flex-end;
            }
        }

        .nb__jobs__cta {
            max-width: 538px;
            width: 90%;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.22);
            background-image: linear-gradient(to left, #f44751, #e52228);
            
            margin-top: -31px;
            position: relative;
            z-index: 3;

            @include media-breakpoint-up(md) {
                margin-top: -45px;
            }

            @include media-breakpoint-up(xl) {
                width: 100%;
            }

            .nb__jobs_cta__wrapper {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                display: flex;
                padding: 10px 15px;
                justify-content: space-between;
                align-items: center;
                line-height: 1;
                color: white;
                transition: all 0.6s ease-in-out 0.2s;
                background-color: $red;
                z-index: 99;

                @include media-breakpoint-down(sm) {
                    justify-content: space-evenly;
                }

                @include media-breakpoint-up(md) {
                    padding: 20px 30px;
                }

                &:hover {
                    background-color: transparent;
    
                    .arrows {
                        i:nth-of-type(1),
                        i:nth-of-type(2),
                        i:nth-of-type(3){
                            animation: arrow-move-lr 1s;
                            animation-iteration-count: infinite;
                        }   
                    }
                }
            }
        }

        .nb {
            font-size: 2.5rem;

            @include media-breakpoint-up(md) {
                font-size: 5rem;
            }
        }

        .label {
            font-size: 1.6rem;
            border-left: 1px solid #EF7E82;
            border-right: 1px solid #EF7E82;
            height: 42px;
            line-height: 42px;
            padding: 0 30px;

            @include media-breakpoint-up(md) {
                font-size: 2.8rem;
            }
        }

        .arrows {
            font-size: 1.6rem;

            i:nth-of-type(1),
            i:nth-of-type(2),
            i:nth-of-type(3){
                display:none;
            }

            @include media-breakpoint-up(md) {
                i:nth-of-type(1),
                i:nth-of-type(2),
                i:nth-of-type(3){
                    display:inline-block;

                }
            }
        }
    }
}