body[data-view="contact"] {

    #main {
        overflow: hidden;
        max-height:fit-content;

        #contact_banner {
            .hero__banner__inner {
                z-index: 0;
            }
        }
    }

    #topcta {
        margin-top: -85px;
    }

    .hero__banner {
        h1 {
            margin-top: -40px;
        }
    }

    section {
        &#contact_form {
            margin-top: 30px;

    

            .container {
                text-align: center;
                margin: 0 auto;

                .content {
                    max-width: 735px;
                    width: 100%;
                    margin: 0 auto;
                }

                .section__title {
                    font-size: 4.4rem;
                    margin-bottom: 20px;

                }

                .form-messages {
                    display: none;
                    margin-top: 50px;

                    &.show {
                        display: block;
                    }

                    .success {
                        color: $teal;
                        font-weight: bold;
                        opacity: 0;

                        &.show {
                            opacity: 1;
                        }
                    }

                    .error {
                        color: $red;
                        font-weight: bold;
                        opacity: 0;

                        &.show {
                            opacity: 1;
                        }
                    }
                }

                form {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                    flex-wrap: wrap;

                    input#location{
                        // don't fall in the jar
                        opacity:0;
               
                        height:0px;
                        width:0px;
                        overflow:hidden;
                        
                    }

                    button {
                        padding: 0;
                        border: none;
                        color: inherit;
                        background-color: transparent;
                        cursor: pointer;
                    }

                    .send-form {
                        color: white;
                        margin: 0 auto;

                        &:hover {
                            cursor: pointer;
                        }

                        &[disabled] {
                            opacity: .5;
                            cursor: not-allowed;
                        }
                    }

                    .field {
                        &--input {
                            max-width: 362px;
                            width: 49%;
                            margin-bottom: 14px;

                            > * {
                                width: 100%;
                            }
                        }

                        &--fullwidth {
                            flex-grow: 1;
                            max-width: 100%;
                            width: 100%;

                            > * {
                                width: 100%;
                            }
                        }

                        &--submit {
                            text-align: center;
                            margin-top: 30px;
                            width: 100%;
                        }

                        &.error {
                            > * {
                                border-color: #e52228 !important;
                                > * {
                                    border-color: #e52228  !important;
                                }
                            }

                            &--required {
                                .error-msg--required {
                                    display: block;
                                }
                            }

                            &--invalid {
                                .error-msg--invalid {
                                    display: block;
                                }
                            }
                        }

                        input {
                            height: 45px;
                            border-radius: 5px;
                            line-height: 1;
                            border: solid 1px #374448;
                            font-size: 1.4rem;
                            padding: 0 14px;
                        }

                        textarea {
                            border: solid 1px #374448;
                            border-radius: 5px;
                            height: 250px;
                            padding: 10px 14px;
                            font-size: 1.4rem;
                        }

                        .error-msg {
                            display: none;
                            font-size: 1.4rem;
                            color: #e52228;
                            margin-top: 1rem;
                            margin-bottom: 0;
                            line-height: 1;
                        }
                    }

                    .ss-main {
                        // Overriding vendor style
                        max-width: 100% !important;

                        .ss-search {
                            display: none;
                        }

                        .ss-content {
                            border: solid 1px #374448;
                        }

                        .ss-single-selected {
                            border-radius: 5px;
                            height: 45px;
                            color: #2b2b2b;
                            padding-left: 12px;
                            border: solid 1px #374448;

                            .ss-arrow {
                                padding-right: 12px;

                                &::before {
                                    content: "";
                                    width: 1px;
                                    height: 22px;
                                    margin-right: 12px;
                                    background-color: #e3e8eb;
                                }
                            }
                        }
                    }
                }
            }
        }

        &#contact_map {
            margin-top: 50px;
            background-color: #374448;
            padding: 105px 0 80px;
            position: relative;
            z-index: 1;

            #map {
                width: 50%;
                height: 100%;
                max-height: 605px;
                position: absolute !important;
                left: 20px;

                @include media-breakpoint-down(md) {
                    max-width: 720px;
                    top: 250px;
                    width: 100%;
                    height: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @include media-breakpoint-down(sm) {
                    max-width: 540px;
                    max-height: 460px;
                    top: 250px;
                    width: 100%;
                }

                @include media-breakpoint-down(xs) {
                    top: 250px;
                    max-width: 320px;
                    max-height: 320px;
                    width: 95%;
                    height: 25%;
                }
            }

            .content {
                display:flex;
                justify-content: space-between;
                flex-wrap: wrap;

                @include media-breakpoint-up(lg) {
                    padding-left: 50px;
                }

                .section__title{
                    width:100%;
                    color: white;
                    font-size: 4.5rem;
                    margin-bottom:40px;
                }

                .contact__location__wrapper {

                    display:flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        padding-top: 675px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-top: 535px;
                    }

                    @include media-breakpoint-down(xs) {
                        padding-top: 390px;
                    }

                    .contact__location {
                        transition: 250ms ease background;
                        cursor: pointer;
                        color: white;
                        line-height: 1;
                        font-size: 1.6rem;
                        width: 45%;
                        margin-bottom:30px;
                        padding: 1rem;

                        @include media-breakpoint-down(md) {
                            width: 33%;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 45%;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }

                        h3 {
                            font-size: 1.6rem;
                            font-weight: 600;
                        }

                        &:hover, &.active {
                            background: rgba(255,255,255,0.1);
                            border-radius: 1rem;
                        }
                    }

                }

            }

        }
    }

    .line {
        width: 10px;
        position: absolute;
        z-index: 0;
        opacity:1;
        transition: opacity 2s ease-in;
        cursor: auto;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &--blue {
            background-image: url("../../../images/lines/line-blue.png");
            background-repeat: no-repeat;
        }

        &--pink {
            background-image: url("../../../images/lines/line-pink.png");
            background-repeat: no-repeat;
        }

        /* individual lines positioning */

        /* blue */
        &.line1 {
            height: 632px;

            @include media-breakpoint-up(md) {
                left: 1%;
                top: 23%;
            }

            @include media-breakpoint-up(xl) {
                top: 15%;
                left: 2%;
            }

            @include media-breakpoint-up(laptop) {
                left: 3%;
            }

        }

        /* blue */
        &.line2 {
            height: 426px;

            @include media-breakpoint-up(md) {
                left: 6%;
                top: 65%;
            }

            @include media-breakpoint-up(xl) {
                height: 630px;
                left: 7%;
                top: 30%;
            }

            @include media-breakpoint-up(laptop) {
                left: 12%;
            }

        }

        /* blue */
        &.line3 {
            height: 425px;

            @include media-breakpoint-up(xl) {
                height: 630px;
                left: 11%;
                top: 60%;
            }

            @include media-breakpoint-up(laptop) {
                left: 17%;
            }
        }

        /* pink */
        &.line4 {
            height: 630px;

            @include media-breakpoint-up(md) {
                right: 9%;
                top: 80%;
            }

            @include media-breakpoint-up(xl) {
                right: 8%;
                top: 30%;
            }

            @include media-breakpoint-up(laptop) {
                right: 14%;
            }
        }

        /* pink */
        &.line5 {
            height: 600px;

            @include media-breakpoint-up(md) {
                right: 3%;
                top: 20%;
            }

            @include media-breakpoint-up(xl) {
                right: 3%;
                top: 50%;
            }

            @include media-breakpoint-up(laptop) {
                right: 9%;
            }
        }
    }
}

/* ANIMATIONS */

body.animate {

    #contact_form {
        transform: translateY(42.5px);
        opacity: 0;
        overflow: hidden;
    }

    .line {
        opacity: 0;
    }
}

body:not(.animate) {
    #contact_form {
        transform: translateY(0);
        opacity: 1;
        transition: all 1s ease-in-out 1.5s;
    }

}
