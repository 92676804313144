.top__cta {
    transition: top 1s ease-in-out;
    pointer-events:none;

    .promark-icon-truck{
        pointer-events: auto;
    }


    @include media-breakpoint-up(md) {
        top: 0px;
    }

    &.block {
        position: relative;
        top: -45px;
        z-index: 5;
    }


    &.fixed--anim {
        z-index: 10;

        .top__cta--scroll__down {
            display: none;
        }

        .top__cta--contact {
            display: block;


            //COLLAPSE ON SCROLL DOWN
            &.collapse {
                opacity: 0 !important;
                max-width: 0;
            }
        }
    }

    &.fixed {
        @include media-breakpoint-up(md) {
            position: fixed;
            bottom: 100px;
            right: 0;
            top: auto;
            width: 100%;
            height: 85px;

            .top__cta--scroll__down {
                display: none;
            }

            .top__cta--contact {
                display: block;

                //COLLAPSE ON SCROLL DOWN
                &.collapse {
                    display: none;
                }
            }
        }
    }

    .top__cta__inner {
        height:100%;
        .container {
            height:100%;
            > .row {
                height:100%;
                .top__cta__wrapper {
                    /*
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;

                     */
                    position: relative;
                    max-width: 350px;
                    margin: 0 auto;
                    padding: 0;
                    height:100%;

                    @include media-breakpoint-up(md) {
                        justify-content: flex-start;
                        max-width: 100%;
                    }

                    i {
                        &.promark-icon-truck {
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 10;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            width: 85px;
                            height: 85px;
                            background-color: $red;
                            color: white;
                            font-size: 4rem;
                            transition: all 0.5s ease-in-out;
                            background-image: linear-gradient(to left, #f44751, #e52228);

                            -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                            -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);

                            &::before {
                                position: relative;
                                z-index: 2;
                            }

                            /* Background rouge rond qui fade out au hover */
                            span.bg {
                                border-radius: 50%;
                                width: 85px;
                                height: 85px;
                                background-color: $red;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 1;
                            }

                            &:hover {
                                cursor: pointer;

                                span.bg {
                                    background-color: transparent;
                                }

                            }
                        }
                    }

                    a {
                        display: flex;
                        justify-content: space-between;
                        height: 100%;
                        align-items: center;

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &--scroll__down {
        height: 85px;
        max-width: 350px;
        width: 100%;
        border-radius: 50px;
        background-color: white;
        font-size: 1.6rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        order: 2;


        @include media-breakpoint-down(sm) {

        }

        a {
            color: $gray;
            margin: 0 auto;
            display: block;

            @include media-breakpoint-down(sm) {
                pointer-events: none;
            }
        }


        @include media-breakpoint-up(md) {
            order: 1;
            margin-top: 0;
        }

        p {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            line-height: 1;
            font-size: 1.6rem;

            @include media-breakpoint-up(md) {
                margin: 0 50px;
            }

            i {

                &:first-of-type {
                    margin-right: 28px;
                }

                &:last-of-type {
                    margin-left: 28px;
                }
            }
        }

    }

    /* Planning to Excavate? */
    &--contact {
        height: 85px;
        width: 100%;
        max-width: 350px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        position: relative;
        overflow: hidden;
        order: 1;
        margin: 0 auto;


        @include media-breakpoint-up(md) {
            max-width: 410px;
            order: 2;
            margin: 0;
            position: absolute;
            right: 0;
            white-space: nowrap;
        }


        @include media-breakpoint-up(lg) {
            max-width: 510px;
        }

        p {
            height: 100%;
            margin-left: 50px;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1;
            width: 215px;

            @include media-breakpoint-up(lg) {
                line-height: 1.63;
                width: 300px;
            }

            @include media-breakpoint-only(md) {
                margin-left: 30px;

            }

            strong,
            span {
                display: block;
            }

            span {
                font-size: 1.4rem;
                color: $gray;
                max-width: 200px;
                white-space: pre-wrap;

                @include media-breakpoint-up(md) {
                    font-size: 1.4rem;
                    max-width: 220px;
                }

                @include media-breakpoint-up(lg) {
                    max-width: none;
                    line-height: 1;
                }
            }

            strong {
                font-size: 1.7rem;
                color: $richBlack;
                margin-bottom: 3px;

                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    font-size: 2rem;
                }
            }
        }
    }
}

body.animate {
    section {
        &#topcta {
            opacity: 0;
            transform: translateY(0px); // fix de position au reload si pas au top de page

            .top__cta--scroll__down {
                opacity: 0;
            }

            .promark-icon-truck {
                opacity: 0;
            }

            .top__cta--contact {
                opacity: 0;
                max-width: 0;
            }
        }
    }
}

#main {
    .toggleCta {
        &:hover {
            .top__cta--contact {
                opacity: initial !important;
                max-width: 510px;
            }
        }
    }
}


body:not(.animate) {
    section {
        &#topcta {
            opacity: 1;
            transition: opacity 1s ease-in-out 1.5s,
            transform 1s ease-in-out 1.5s,
            top 0.5s ease-in-out;


            .top__cta--scroll__down {
                opacity: 1;
                transition: opacity 1s ease-in-out 2s;
            }

            .promark-icon-truck {
                opacity: 1;
                transition: opacity 1s ease-in-out 2s;
            }

            .top__cta--contact {
                opacity: 0;
                transition: opacity 0.9s ease-in-out 2.2s;

                @include media-breakpoint-up(md) {
                    opacity:1;
                    transition: opacity 0.9s ease-in-out 2.2s,
                    max-width 1s ease-in-out 2.2s;
                }
            }
        }
    }
}

// Hide google recaptcha badge
.grecaptcha-badge{
    opacity:0;
    pointer-events: none;
}