/*
<= lg :
    Burger menu visible, Nav cachée

    ".open" du menu mobile rétrécie le logo le header est fixed
        Affiche un fond noir avec les items de menu blanc


 All:
    Scroll down dépassé 300px header est mis à ".fixed" avec 82px height
        Header a un background blanc
        Logo est switché à sa version noire et est rétrécie à 70%
        Items de menu deviennent noir

    Scroll up à moins de 299px remet le header en absolute état par défaut avec height 150px.

 */



header {
    width: 100%;
    background-color: transparent;
    z-index:100;
    top: 0;
    left: 0;
    height:150px;
    position:absolute;
    transition:
    height 0.3s ease-in-out 0.3s,
    background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out 0.3s;
    overflow:hidden;

    @include media-breakpoint-up(xl){
        overflow:visible;
    }

    .promark__logo__img--black{
        display:none;
    }

    &.hide {
        display: none;
    }

    &.scrolled:not(.open){
        transform: translateY(-150px);
    }

    &.fixed:not(.open){
        position:fixed;
        background: rgba(255,255,255, 1);
        transition:background-color 0.2s ease-in-out;
        height:82px;
        transform: translateY(0);
       
        .promark__logo__img--black{
            display:block;
        }

        .promark__logo__img--white{
            display:none;
        }

        nav{
            margin-top:0;

            .burger{
                top:35px;
                .bar{
                    background-color: black;
                }
            }
        }

        @include media-breakpoint-up(xl){
            nav{
                ul.nav-main{
                    li{
                        a{
                            color:black;
                            opacity:0.7;
                            transition: opacity 0.2s ease-in-out;

                            &:hover{
                                opacity:1;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open{
        position:fixed;
        z-index:100;
        height:100vh;
        width:100%;
        background-color: black;
        overflow:auto;
        z-index:10;



        /* Override au screen resize */
        @include media-breakpoint-up(xl){
            background-color: transparent;
            height: 150px;
            z-index:initial;
        }

        nav{
            margin-top:0;
            flex-direction: column;
            height: calc(100vh - 25px);

            ul.nav-main{
                visibility:visible;
                opacity:1;
                align-self: center;
                pointer-events:auto;
                transition: opacity 0.2s ease-in 0.6s;

                @media screen and (orientation: landscape) {

                    margin-top: 20px;

                        li{
                            margin:5px 0;

                            @include media-breakpoint-up(lg){
                                margin:20px 0;
                            }

                            a{
                                font-size:2rem;

                                @include media-breakpoint-up(md){
                                    font-size:3rem;
                                }
                                @include media-breakpoint-up(lg){
                                    font-size:3.7rem;
                                }
                            }
                        }
                 }
            }

            .copyright{
                visibility:visible;
                pointer-events:auto;
                opacity:0.68;

                @media screen and (orientation: landscape) {
                    padding: 10px 0;
                }
            }
        }
    }

    /* Nav Stuff */
    nav {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        position:relative;
        flex-direction: row;

        .copyright{
            visibility:hidden;
            pointer-events:none;
            color:white;
            font-size:1.4rem;
            opacity:0;
            padding: 20px 0;
            text-align: center;
            transition: opacity 0.2s ease-in-out 0.8s;

            a{
                color: white;
                text-decoration:underline;
            }
        }

        @include media-breakpoint-up(xl){
            height:auto;

            .copyright{
                display:none;
            }
        }


        .promark__logo {
            display: inline-block;
            position:relative;
            left:-13px;

            @include media-breakpoint-up(lg){
                width: 18.5rem;
                height: 7.9rem;
            }
        }

        ul.nav-main {
            list-style-type: none;
            opacity:0;
            visibility:hidden;
            pointer-events:none;
            padding:0;
            // position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            width:100%;
            

            @include media-breakpoint-up(xl){
                width: 50%;
                justify-content: space-between;
                flex-direction:row;
                padding-top: 30px;
                opacity:1;
                pointer-events:auto;
                transform:none;
                position:static;
                visibility:visible;
                

            }
            @include media-breakpoint-up(laptop){
                width: 40%;
            }

            .cross{
                display:none;
            }

            li {
                margin: 15px 0;

                .return_link{
                    display:none;
                }

                &.menu-item-has-children{
                    padding-bottom:0;
                    position: relative;

                    a.nav-main-link{
                        position: relative;
 
    
                        &::after{
                            content: "+";
                            font-size: 30px;
                            color: white;
                            margin-left:20px;
    
                            @include media-breakpoint-up(xl){
                                display:none; 
                            }
    
                        }
                    }



                    ul.nav-drop{
                        pointer-events: none;
                        opacity:0;
                        top:0;
                        transition: all 0.2s ease-in-out;
                    }


                    &:hover{
                        @include media-breakpoint-up(xl){
                            ul.nav-drop.service-submenu{
                                opacity:1;
                                pointer-events: auto;
                                display:grid;
                            }
                        }
                    }
               

                    &.active{
                        a.nav-main-link{
                            position: relative;
                            &::after{
                                content: "-";
                            }
                        }
                        ul.nav-drop.service-submenu{
                            opacity:1;
                            pointer-events: auto;
                            display:grid;
                        }
                    }

             
          
                    ul.nav-drop.service-submenu{
                        border-radius: 22px;
                        list-style-type: none;
                        background-color:white;
                        grid-template-columns: 1fr 1fr;
                        
                        width:100%;
                        padding:0;
                        margin-top: 20px;
                        display:none;
                        @include media-breakpoint-up(xl){
                            left:30px;
                            top:50px;
                            transform:translateX(-50%);
                            position:absolute;
                            margin-top: 0;
                            display:grid;
                            width: 466px;
                            height: 250px;

                        }
                        

                        .arrow{
                            display:none;
                            width: 0px;
                            height: 0px;
                            border-left: 15px solid transparent;
                            border-right: 15px solid transparent;
                            border-bottom: 15px solid white;
                            position:absolute;
                            left:50%;
                            top:-15px;
                            transform: translateX(-50%);

                            @include media-breakpoint-up(xl){
                                display:block;
                            }
                        }
                        .cross{
                            display:block;
                            position: absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height: 100%;
                            pointer-events: none;
                            display:none;

                            @include media-breakpoint-up(xl){
                                display:block;
                            }

                            .vertical{
                                background-color:#D6D5D6;
                                height:100%;
                                width:1px;
                                position:absolute;
                                left:50%;
                                top:0;
                                transform:translateX(-50%);
                            }
                            .horizontal{
                                background-color:#D6D5D6;
                                height:1px;
                                width:100%;
                                position:absolute;
                                left:0;
                                top:50%;
                                transform:translateY(-50%);
                            }
                        }

                        .return_link,
                        .parent-link_mobile{
                            display:none;
                        }

                        li{
                           
                            padding: 10px 25px;
                      


                            @include media-breakpoint-up(xl){
                                padding: 20px 25px;
                                display: flex;
                                align-items: center;
                              
                            }
                     
            
                            .square{
                                width: 33px;
                                height:33px;
                                transform: rotate(60deg);
                                background-color: black;   
              
                            }
    
                            a{
                              
                                font-weight:700;
                                line-height:normal;
                                color: #231F20;
                                width: calc(100% - 33px);
                                box-sizing: border-box;
                                font-size:16px;
                                margin-top:15px;
                               
                               
                                @include media-breakpoint-up(xl){
                                    font-size: 21px;
                                    display:grid;
                                    grid-template-columns: 1fr 3fr;
                                }

                                @include media-breakpoint-up(xl){
                                    padding-left:23px;
                                    margin-top:0;
                                }


                                span{
                                    display:block;
                                    margin-top:20px;
                                   

                                    @include media-breakpoint-up(xl){
                                        margin-left:20px;
                                        margin-top:0;
                                    }
                                }
                            }

                            &:nth-of-type(1){
                                .square{
                                    background-color: #086480;
                                }
                            }
                            &:nth-of-type(2){
                                .square{
                                    background: #E52129;
                                    transform: rotate(125deg);
                                } 
                            }
                            &:nth-of-type(3){
                                .square{
                                    background: #9ACDDB;
                                }
                            }
                            &:nth-of-type(4){
                                .square{
                                    background: #231F20;
                                    transform: rotate(125deg);
                                }  
                            }
                        }

                
                    }

                }

                @include media-breakpoint-up(xl){
                    &.menu-item-has-children{
                        position:relative;
                        padding-bottom:30px;

            
                    }
                }


                @include media-breakpoint-up(sm){
                    margin: 20px 0;
                }

                @include media-breakpoint-up(xl){
                    margin:0;
                }



                a {
                    color: white;
                    font-size: 2.7rem;
                    opacity:0.8;
                    transition: opacity 0.2s ease-in-out;

                    &:hover{
                        opacity:1;
                    }


                    @include media-breakpoint-up(md){
                        font-size: 3.7rem;
                    }

                    @include media-breakpoint-up(xl){
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    /* Burger stuff */
    .burger{
        width: 30px;
        height: 25px;
        position: absolute;
        right: 0;
        display: flex;
        top:44px;
        flex-wrap: wrap;
        justify-content: flex-end;
        z-index:20;

        @include media-breakpoint-down(lg) {
            cursor: pointer;
        }

        @include media-breakpoint-up(xl){
            display:none;
        }


        &:hover{
            cursor:pointer;

            .bar{
                &:nth-of-type(2),
                &:nth-of-type(3){
                    width: 100%;
                }
            }
        }

        &.open{
            justify-content: center;
            top:35px;

            .bar{
                &.middle{
                    width:100%;
                }
                &:nth-of-type(1),
                &:nth-of-type(3){
                    width: 50%;
                }
            }
        }

        .bar{
            height:2px;
            width: 100%;
            background-color: white;
            display:block;
            transition: all 0.2s ease-in-out;

            &:nth-of-type(2),
            &:nth-of-type(3){
                width: 60%;
            }
        }
    }

}



body.animate {
    .promark__logo{
        opacity:0;
        transform: translateY(-100px);
    }

    @include media-breakpoint-up(xl){
        .nav-main{
            opacity: 0;
        }
    }
}


body:not(.animate) {
    .promark__logo{
        opacity: 1;
        transform: translateY(0);
        transition: all 0.8s ease-in-out 0.6s;
    }

    header.open{
        .promark__logo img{
            transition: all 0.4s ease-in-out;
        }
    }

    @include media-breakpoint-up(xl){
        .nav-main{
            opacity: 1;
            transition: all 0.4s ease-in-out 1s;
        }
    }

}