/* LEEROY scroll effect */
html.has-scroll-smooth {

    overflow: hidden;

    body{
        overflow: hidden;
    }

    @include media-breakpoint-up(md) {

        .c-scrollbar {
            width: 10px;
            position: absolute;
            right: 0;
            top: 0;
            height: 100vh;
            transform-origin: center right;
            transform: scaleX(1);
            transition: transform .2s linear;
            z-index: 9999;
        }
        .c-scrollbar_thumb {
            background-color: $red;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 4px;
        }
        #main{
            position: relative;
            box-sizing: border-box;
            overflow: hidden;
            width: 100vw;

            div, img {

                // GENERAL ANIM BLOCK
                &.animate {
                    opacity: 0;
                    transition: opacity 1.5s ease;

                    // HEADER LINES
                    &.line1, &.line2, &.line3, &.line4, &.line5 {
                        transition: opacity 1.5s ease 2.5s;
                    }
                    // BODY LINES
                    &.line6, &.line7, &.line8, &.line9, &.line10,&.line11, &.line12, &.line13, &.line14, &.line15, &.line16 {
                        transition: opacity 1.5s ease;
                    }

                    &.is-inview {
                        opacity: 1;
                    }
                }

                // SERVICES EXCLUDE FIRST CARD
                #services_list_block1_b, #services_list_block2, #career_types, #plain_text_career_1 {
                    .cards-animate {
                        opacity: 0;
                        transform: translateY(50px);
                        transition: all 1s ease-in-out;
                    }
                    &.is-inview {
                        .cards-animate {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }

                // Animation sur les formes (homepage + career)
                #cards_text_career_1 {
                    .cards__text__inner {
                        &.animate  {
                            &::after {
                                opacity: 0;
                                right: -20%;
                                transition: all 1.5s ease;
                            }
                        }
                        &.is-inview {
                            &::after {
                                opacity: 1;
                                right: 0;
                            }
                        }
                    }
                }

                #cards_text_home_2 {
                    .cards__text__inner {
                        &.animate  {
                            &::before {
                                opacity: 0;
                                left: -20%;
                                transition: all 1.5s ease;
                            }
                        }
                        &.is-inview {
                            &::before {
                                opacity: 1;
                                @include media-breakpoint-up(md) {
                                    left: -60px;
                                }
                                @include media-breakpoint-up(laptop) {
                                    left: -25px;
                                }
                                @include media-breakpoint-up(hd) {
                                    left: -0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
