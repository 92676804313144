/* Cards with text (title, description and cta) and multiple images */

.cards__text {
    padding: 60px 0;

    .pictures--left {
        position:relative;

        @include media-breakpoint-up(laptop) {
            left: 0;
        }
        @include media-breakpoint-up(hd) {
           left: -150px;
        }

    }

    .pictures--right {
        position:relative;

        @include media-breakpoint-up(laptop) {
            right: 0;
        }

        @include media-breakpoint-up(hd) {
            right: -150px;
        }

    }


    .pictures {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        pointer-events: none;

        .imgWrap {
            width: calc(50% - 20px);
            max-width: 380px;

            img {
                width: calc(100% - 20px);
                margin: 10px;
            }
        }

    }


    .content {
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            max-width: 480px;
            margin-left: 30px;
        }

        .section__title {
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
                margin-bottom: 60px;
            }
        }

        .btn {
            margin-top: 80px;

        }
    }
}
