@charset "UTF-8";

@font-face {
    font-family: "promark-font";
    src:url("../../fonts/promark-font/promark-font.eot");
    src:url("../../fonts/promark-font/promark-font.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/promark-font/promark-font.woff") format("woff"),
    url("../../fonts/promark-font/promark-font.ttf") format("truetype"),
    url("../../fonts/promark-font/promark-font.svg#promark-font") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "promark-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="promark-icon-"]:before,
[class*=" promark-icon-"]:before {
    font-family: "promark-font" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.promark-icon-ios-arrow-down:before {
    content: "\61";
}
.promark-icon-chevron-left:before {
    content: "\62";
}
.promark-icon-chevron-right:before {
    content: "\63";
}
.promark-icon-chevron-up:before {
    content: "\64";
}
.promark-icon-ios-arrow-back:before {
    content: "\65";
}
.promark-icon-ios-arrow-left:before {
    content: "\66";
}
.promark-icon-ios-arrow-forward:before {
    content: "\67";
}
.promark-icon-ios-arrow-right:before {
    content: "\68";
}
.promark-icon-ios-arrow-up:before {
    content: "\69";
}
.promark-icon-chevron-down:before {
    content: "\6a";
}
.promark-icon-car:before {
    content: "\6b";
}
.promark-icon-direction:before {
    content: "\6c";
}
.promark-icon-schedule:before {
    content: "\6d";
}
.promark-icon-safety:before {
    content: "\6e";
}
.promark-icon-retirement:before {
    content: "\6f";
}
.promark-icon-training:before {
    content: "\70";
}
.promark-icon-profil:before {
    content: "\72";
}
.promark-icon-private:before {
    content: "\73";
}
.promark-icon-vital:before {
    content: "\74";
}
.promark-icon-privateutility:before {
    content: "\75";
}
.promark-icon-house:before {
    content: "\76";
}
.promark-icon-street-light:before {
    content: "\77";
}
.promark-icon-traffic:before {
    content: "\78";
}
.promark-icon-pipeline:before {
    content: "\79";
}
.promark-icon-water:before {
    content: "\7a";
}
.promark-icon-underground:before {
    content: "\41";
}
.promark-icon-gpr:before {
    content: "\42";
}
.promark-icon-naturalgas:before {
    content: "\43";
}
.promark-icon-truck:before {
    content: "\71";
}
